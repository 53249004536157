import {Link} from "react-router-dom";

import image3 from '../../assets/images/demos/digital-agency/slides/image3.jpg'
import image4 from '../../assets/images/demos/digital-agency/slides/image4.jpg'
import client2 from "../../assets/images/logos/owlocular.png";

function Home() {
	return (
		<div>
			<div className="slider-container rev_slider_wrapper" style={{height: 750}}>
				<div
					id="revolutionSlider"
					className="slider rev_slider"
					data-version="5.4.8"
					data-plugin-revolution-slider
					data-plugin-options="{'delay': 9000, 'gridwidth': 1110, 'gridheight': [750,750,750,1250], 'responsiveLevels': [4096,1200,992,500]}"
				>
					<ul>
						<li className="slide-overlay slide-overlay-level-7" data-transition="fade">
							<img
								src={image3}
								alt=""
								data-bgposition="center center"
								data-bgfit="cover"
								data-bgrepeat="no-repeat"
								data-kenburns="on"
								data-duration="9000"
								data-ease="Linear.easeNone"
								data-scalestart="115"
								data-scaleend="100"
								data-rotatestart="0"
								data-rotateend="0"
								data-offsetstart="0 -200"
								data-offsetend="0 200"
								data-bgparallax="0"
								className="rev-slidebg"
							/>

							<div
								className="tp-caption tp-caption-overlay-opacity top-label font-weight-semibold"
								data-frames='[{"delay":1000,"speed":1000,"sfxcolor":"#212529","sfx_effect":"blockfromleft","frame":"0","from":"z:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":500,"sfxcolor":"#212529","sfx_effect":"blocktoleft","frame":"999","to":"z:0;","ease":"Power4.easeOut"}]'
								data-x="left"
								data-hoffset="['0','30','30','30']"
								data-y="center"
								data-voffset="['-65','-65','-69','-73']"
								data-fontsize="['18','18','18','30']"
								data-paddingtop="['10','10','10','12']"
								data-paddingbottom="['10','10','10','12']"
								data-paddingleft="['18','18','18','18']"
								data-paddingright="['18','18','18','18']"
							>
								WE BUILD
							</div>

							<h1
								className="tp-caption tp-caption-overlay-opacity main-label"
								data-frames='[{"delay":1300,"speed":1000,"sfxcolor":"#212529","sfx_effect":"blockfromleft","frame":"0","from":"z:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":500,"sfxcolor":"#212529","sfx_effect":"blocktoleft","frame":"999","to":"z:0;","ease":"Power4.easeOut"}]'
								data-x="left"
								data-hoffset="['0','30','30','30']"
								data-y="center"
								data-fontsize="['50','50','50','60']"
								data-letterspacing="0"
								data-paddingtop="['10','10','10','10']"
								data-paddingbottom="['10','10','10','10']"
								data-paddingleft="['18','18','18','18']"
								data-paddingright="['18','18','18','18']"
							>
								INNOVATIVE SOLUTIONS
							</h1>

							<div
								className="tp-caption d-none d-md-block"
								data-frames='[{"delay":3000,"speed":500,"frame":"0","from":"opacity:0;x:10%;","to":"opacity:1;x:0;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
								data-x="left"
								data-hoffset="['330','360','360','135']"
								data-y="center"
								data-voffset="['30','30','30','-62']"
							>
								<img src="img/slides/slide-white-line.png" alt="" />
							</div>

							<div
								className="tp-caption font-weight-light text-color-light ws-normal"
								data-frames='[{"from":"opacity:0;","speed":300,"to":"o:1;","delay":2000,"split":"chars","splitdelay":0.05,"ease":"Power2.easeInOut"},{"delay":"wait","speed":1000,"to":"y:[100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'
								data-x="left"
								data-hoffset="['3','35','35','35']"
								data-y="center"
								data-voffset="['65','65','65','95']"
								data-width="['690','690','690','800']"
								data-fontsize="['18','18','18','35']"
								data-lineheight="['29','29','29','40']"
							>
								We create amazing solutions for our clients, contact us to learn how we can help.
							</div>

							<Link
								className="tp-caption btn btn-outline btn-primary font-weight-bold"
								to="/services"
								data-frames='[{"delay":3000,"speed":2000,"frame":"0","from":"y:50%;opacity:0;","to":"y:0;o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
								data-x="left"
								data-hoffset="['0','30','30','30']"
								data-y="center"
								data-voffset="['140','140','140','245']"
								data-paddingtop="['15','15','15','30']"
								data-paddingbottom="['15','15','15','30']"
								data-paddingleft="['40','40','40','50']"
								data-paddingright="['40','40','40','50']"
								data-fontsize="['13','13','13','25']"
								data-lineheight="['20','20','20','25']"
							>
								LEARN MORE
							</Link>

							<Link
								className="tp-caption btn btn-primary font-weight-bold"
								to="/contact"
								data-frames='[{"delay":3000,"speed":2000,"frame":"0","from":"y:50%;opacity:0;","to":"y:0;o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
								data-x="left"
								data-hoffset="['185','185','220','340']"
								data-y="center"
								data-voffset="['140','140','140','245']"
								data-paddingtop="['16','16','16','31']"
								data-paddingbottom="['16','16','16','31']"
								data-paddingleft="['40','40','40','50']"
								data-paddingright="['40','40','40','50']"
								data-fontsize="['13','13','13','25']"
								data-lineheight="['20','20','20','25']"
							>
								GET STARTED NOW <i className="fas fa-arrow-right ml-1"></i>
							</Link>
						</li>
						<li className="slide-overlay slide-overlay-level-7" data-transition="fade">
							<img
								src={image4}
								alt=""
								data-bgposition="center center"
								data-bgfit="cover"
								data-bgrepeat="no-repeat"
								data-kenburns="on"
								data-duration="9000"
								data-ease="Linear.easeNone"
								data-scalestart="115"
								data-scaleend="100"
								data-rotatestart="0"
								data-rotateend="0"
								data-offsetstart="0 400px"
								data-offsetend="0 -400px"
								data-bgparallax="0"
								className="rev-slidebg"
							/>

							<div
								className="tp-caption tp-caption-overlay-opacity top-label font-weight-semibold"
								data-frames='[{"delay":1000,"speed":1000,"sfxcolor":"#212529","sfx_effect":"blockfromleft","frame":"0","from":"z:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":500,"sfxcolor":"#212529","sfx_effect":"blocktoleft","frame":"999","to":"z:0;","ease":"Power4.easeOut"}]'
								data-x="left"
								data-hoffset="['550','550','550','450']"
								data-y="center"
								data-voffset="['-65','-65','-69','-73']"
								data-fontsize="['18','18','18','30']"
								data-paddingtop="['10','10','10','12']"
								data-paddingbottom="['10','10','10','12']"
								data-paddingleft="['18','18','18','18']"
								data-paddingright="['18','18','18','18']"
							>
								WE PROVIDE
							</div>

							<div
								className="tp-caption tp-caption-overlay-opacity main-label"
								data-frames='[{"delay":1300,"speed":1000,"sfxcolor":"#212529","sfx_effect":"blockfromleft","frame":"0","from":"z:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":500,"sfxcolor":"#212529","sfx_effect":"blocktoleft","frame":"999","to":"z:0;","ease":"Power4.easeOut"}]'
								data-x="left"
								data-hoffset="['550','550','550','450']"
								data-y="center"
								data-fontsize="['50','50','50','60']"
								data-paddingtop="['10','10','10','12']"
								data-paddingbottom="['10','10','10','12']"
								data-paddingleft="['18','18','18','18']"
								data-paddingright="['18','18','18','18']"
							>
								CREATIVE CONSULTATIONS
							</div>

							<div
								className="tp-caption font-weight-light text-color-light ws-normal"
								data-frames='[{"from":"opacity:0;","speed":300,"to":"o:1;","delay":2000,"split":"chars","splitdelay":0.05,"ease":"Power2.easeInOut"},{"delay":"wait","speed":1000,"to":"y:[100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'
								data-x="left"
								data-hoffset="['550','550','550','450']"
								data-y="center"
								data-voffset="['65','65','65','105']"
								data-width="['600','600','600','600']"
								data-fontsize="['18','18','18','30']"
								data-lineheight="['29','29','29','40']"
							>
								Amazing solutions for our clients, contact us to learn how we can help.
							</div>

							<Link
								className="tp-caption btn btn-primary font-weight-bold"
								to="/contact"
								data-frames='[{"delay":3000,"speed":2000,"frame":"0","from":"y:50%;opacity:0;","to":"y:0;o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
								data-x="left"
								data-hoffset="['550','550','550','450']"
								data-y="center"
								data-voffset="['140','140','140','235']"
								data-paddingtop="['16','16','16','31']"
								data-paddingbottom="['16','16','16','31']"
								data-paddingleft="['40','40','40','50']"
								data-paddingright="['40','40','40','50']"
								data-fontsize="['13','13','13','25']"
								data-lineheight="['20','20','20','25']"
							>
								GET STARTED NOW <i className="fas fa-arrow-right ml-1"></i>
							</Link>
						</li>
					</ul>
				</div>
			</div>

			<div className="container py-4 my-5">
				<div className="row justify-content-center mb-4">
					<div className="col-md-12 col-lg-10">
						<div className="tabs tabs-bottom tabs-center tabs-simple custom-tabs-style-1 mt-2 mb-3">
							<ul className="nav nav-tabs mb-3">
								<li className="nav-item active">
									<a className="nav-link" href="#tabsNavigationSimpleIcons1" data-toggle="tab">
										<span className="featured-boxes featured-boxes-style-6 p-0 m-0">
											<span className="featured-box featured-box-primary featured-box-effect-6 p-0 m-0">
												<span className="box-content p-0 m-0">
													<i className="icon-featured icon-bulb icons"></i>
												</span>
											</span>
										</span>
										<p className="text-color-dark font-weight-bold mb-0 pt-2 text-2 pb-0">Strategy</p>
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="#tabsNavigationSimpleIcons2" data-toggle="tab">
										<span className="featured-boxes featured-boxes-style-6 p-0 m-0">
											<span className="featured-box featured-box-primary featured-box-effect-6 p-0 m-0">
												<span className="box-content p-0 m-0">
													<i className="icon-featured icon-mustache icons"></i>
												</span>
											</span>
										</span>
										<p className="text-color-dark font-weight-bold mb-0 pt-2 text-2 pb-0">Creative</p>
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="#tabsNavigationSimpleIcons3" data-toggle="tab">
										<span className="featured-boxes featured-boxes-style-6 p-0 m-0">
											<span className="featured-box featured-box-primary featured-box-effect-6 p-0 m-0">
												<span className="box-content p-0 m-0">
													<i className="icon-featured icon-puzzle icons"></i>
												</span>
											</span>
										</span>
										<p className="text-color-dark font-weight-bold mb-0 pt-2 text-2 pb-0">Development</p>
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="#tabsNavigationSimpleIcons4" data-toggle="tab">
										<span className="featured-boxes featured-boxes-style-6 p-0 m-0">
											<span className="featured-box featured-box-primary featured-box-effect-6 p-0 m-0">
												<span className="box-content p-0 m-0">
													<i className="icon-featured icon-rocket icons"></i>
												</span>
											</span>
										</span>
										<p className="text-color-dark font-weight-bold mb-0 pt-2 text-2 pb-0">Marketing</p>
									</a>
								</li>
							</ul>
							<div className="tab-content">
								<div className="tab-pane active" id="tabsNavigationSimpleIcons1">
									<div className="text-center">
										<p>
											We build solutions as a process. Our strategic work process helps to feel the customer and their needs.
										</p>
									</div>
								</div>
								<div className="tab-pane" id="tabsNavigationSimpleIcons2">
									<div className="text-center">
										<p>
											After we have felt the customer, we pour our creativity into the solutions as if creativity is in our blood.
										</p>
									</div>
								</div>
								<div className="tab-pane" id="tabsNavigationSimpleIcons3">
									<div className="text-center">
										<p>
											We have highly experienced engineers who have international work exposure.
										</p>
									</div>
								</div>
								<div className="tab-pane" id="tabsNavigationSimpleIcons4">
									<div className="text-center">
										<p>
											We have strong connections around and we think we have created a brand that everybody believes in.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col text-center">
						<Link
							className="btn btn-outline btn-primary text-1 font-weight-semibold text-uppercase px-5 btn-py-2 mb-3"
							to="/services"
						>
							Learn More
						</Link>
					</div>
				</div>
			</div>

			<section className="section section-height-3 bg-color-grey-scale-1 border-top-0 m-0">
				<div className="container">
					<div className="row py-3 mb-2">
						<div className="col-lg-6 pr-5 appear-animation" data-appear-animation="fadeInRightShorter">
							<h2 className="font-weight-extra-bold text-color-dark text-6 mb-0">Who We Are</h2>
							<p className="font-weight-normal opacity-8 text-4 mb-3">Always there for our clients, helping them to excel</p>
							<div className="divider divider-primary divider-small divider-small-left">
								<hr className="w-25" />
							</div>
							<p className="opacity-8 mb-4">
								Work exposure in various fields has helped us strengthen our ability. High quality solutions have helped us create our brand.
							</p>
							<p className="opacity-8 mb-3">
								Clients do not need to visit us. Give us a call and we will take everything from there. We are strongly dedicated to you.
							</p>

							<Link className="text-dark font-weight-bold text-2" to="/about">
								VIEW MORE<i className="fas fa-chevron-right text-2 pl-2"></i>
							</Link>
						</div>
						<div className="col-lg-6 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="200">
							<div className="row">
								<div className="col">
									<p className="font-weight-bold text-color-dark text-4 mt-4 pt-3">Main Reasons to Hire Us</p>
									<div className="divider divider-primary divider-small divider-small-left">
										<hr className="w-25" />
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-6">
									<div
										className="feature-box align-items-center mb-2"
										data-appear-animation="fadeInRightShorter"
										data-appear-animation-delay="100"
									>
										<div className="feature-box-icon">
											<i className="icon-screen-tablet icons"></i>
										</div>
										<div className="feature-box-info">
											<p className="opacity-9 mb-0">Mobile Apps</p>
										</div>
									</div>
									<div
										className="feature-box align-items-center mb-2"
										data-appear-animation="fadeInRightShorter"
										data-appear-animation-delay="300"
									>
										<div className="feature-box-icon">
											<i className="icon-layers icons"></i>
										</div>
										<div className="feature-box-info">
											<p className="opacity-9 mb-0">Enterprise Systems</p>
										</div>
									</div>
									<div
										className="feature-box align-items-center mb-2"
										data-appear-animation="fadeInRightShorter"
										data-appear-animation-delay="500"
									>
										<div className="feature-box-icon">
											<i className="icon-magnifier icons"></i>
										</div>
										<div className="feature-box-info">
											<p className="opacity-9 mb-0">Data Analytics</p>
										</div>
									</div>
								</div>
								<div className="col-sm-6">
									<div
										className="feature-box align-items-center mb-2"
										data-appear-animation="fadeInRightShorter"
										data-appear-animation-delay="200"
									>
										<div className="feature-box-icon">
											<i className="icon-screen-desktop icons"></i>
										</div>
										<div className="feature-box-info">
											<p className="opacity-9 mb-0">Corporate Applications</p>
										</div>
									</div>
									<div
										className="feature-box align-items-center mb-2"
										data-appear-animation="fadeInRightShorter"
										data-appear-animation-delay="400"
									>
										<div className="feature-box-icon">
											<i className="icon-doc icons"></i>
										</div>
										<div className="feature-box-info">
											<p className="opacity-9 mb-0">Web Applications</p>
										</div>
									</div>
									<div
										className="feature-box align-items-center mb-2"
										data-appear-animation="fadeInRightShorter"
										data-appear-animation-delay="600"
									>
										<div className="feature-box-icon">
											<i className="icon-user icons"></i>
										</div>
										<div className="feature-box-info">
											<p className="opacity-9 mb-0">Consulting</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* <div className="container" style={{minHeight: 500}}>
				<div className="row justify-content-center pt-4 pb-2 mt-5 mb-4">
					<div className="col-md-8 col-lg-6 text-center appear-animation" data-appear-animation="fadeInUpShorter">
						<h2 className="font-weight-extra-bold text-color-dark text-6 mb-0">Recent Work</h2>
						<p className="text-4 mb-3">Lorem ipsum dolor sit amet consectetur adipiscing</p>
						<div className="divider divider-primary divider-small divider-small-center">
							<hr className="w-25" />
						</div>
					</div>
				</div>
				<div className="row justify-content-center">
					<div className="col-lg-8 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200">
						<div className="carousel-areas mb-0" style={{marginTop: -10}}>
							<div
								className="owl-carousel owl-theme nav-style-1 m-0"
								data-plugin-options="{'autoHeight': true, 'items': 1, 'margin': 10, 'nav': true, 'dots': false, 'stagePadding': 0}"
							>
								<div>
									<div className="col px-2 text-2">
										<div className="row justify-content-between bg-color-grey-scale-1 py-3 px-2 mx-0">
											<div className="col-auto col-md-6">
												<p className="text-dark font-weight-extra-bold mb-0">PORTO DIGITAL AGENCY</p>
											</div>
											<div className="col-auto col-md-6 text-right">
												<span>Website Creation</span>
												<span className="divider-custom mx-1"></span>
												<span>2018</span>
											</div>
										</div>
									</div>
									<a href="#">
										<img alt="" className="img-fluid rounded-0" src="img/previews/areas/areas-1.png" />
									</a>
								</div>
								<div>
									<div className="col px-2 text-2">
										<div className="row justify-content-between bg-color-grey-scale-1 py-3 px-2 mx-0">
											<div className="col-auto col-md-6">
												<p className="text-dark font-weight-extra-bold mb-0">DIGITAL AGENCY</p>
											</div>
											<div className="col-auto col-md-6 text-right">
												<span>Website Update</span>
												<span className="divider-custom mx-1"></span>
												<span>2017</span>
											</div>
										</div>
									</div>
									<a href="#">
										<img alt="" className="img-fluid rounded-0" src="img/previews/areas/areas-2.png" />
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> */}

			<section
				className="section section-height-3 section-dark section-text-light section-no-border m-0 appear-animation"
				data-appear-animation="fadeIn"
			>
				<div className="container my-4">
					<div className="row justify-content-center pb-3 mb-4">
						<div
							className="col-lg-6 text-center appear-animation"
							data-appear-animation="fadeInUpShorter"
							data-appear-animation-delay="200"
						>
							<h2 className="font-weight-bold text-6 mb-0">Testimonials</h2>
							<p className="font-weight-normal opacity-4 text-4 mb-3">This is what people say about us</p>
							<div className="divider divider-primary divider-small divider-small-center">
								<hr className="w-25" />
							</div>
						</div>
					</div>
					<div className="row justify-content-center">
						<div
							className="col-md-8 col-lg-4 mb-5 mb-lg-0 appear-animation"
							data-appear-animation="fadeInLeftShorter"
							data-appear-animation-delay="300"
						>
							<div className="testimonial testimonial-style-2 testimonial-with-quotes testimonial-remove-right-quote mb-0">
								<blockquote>
									<p className="px-xl-2 mb-0">We reached out to Perfex Data to get consultations for some projects. They have high standard consulting services.</p>
								</blockquote>
								<div className="testimonial-author">
									<p>
										<strong className="text-color-light opacity-10 text-4">Tej Prakash</strong>
									</p>
									<p className="text-2 opacity-8">Phoenix Solutions</p>
								</div>
							</div>
						</div>
						<div
							className="col-md-8 col-lg-4 mb-5 mb-lg-0 appear-animation"
							data-appear-animation="fadeIn"
							data-appear-animation-delay="100"
						>
							<div className="testimonial testimonial-style-2 testimonial-with-quotes testimonial-remove-right-quote mb-0">
								<blockquote>
									<p className="px-xl-2 mb-0">We worked together in a Data Analytics Application. They are best at what they do. Good team spirit and leadership is their key.</p>
								</blockquote>
								<div className="testimonial-author">
									<p>
										<strong className="text-color-light opacity-10 text-4">Bikal Sharma</strong>
									</p>
									<p className="text-2 opacity-8">Mervix Technologies</p>
								</div>
							</div>
						</div>
						<div
							className="col-md-8 col-lg-4 appear-animation"
							data-appear-animation="fadeInRightShorter"
							data-appear-animation-delay="300"
						>
							<div className="testimonial testimonial-style-2 testimonial-with-quotes testimonial-remove-right-quote mb-0">
								<blockquote>
									<p className="px-xl-2 mb-0">Perfex Data has helped us in many ways. From Consulting to Mobile Apps, we have received world class services from them.</p>
								</blockquote>
								<div className="testimonial-author">
									<p>
										<strong className="text-color-light opacity-10 text-4">Bikash Bhandary</strong>
									</p>
									<p className="text-2 opacity-8">Owlocular</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<div className="container">
				<div className="row counters counters-sm counters-text-dark py-4 my-5">
					<div className="col-sm-6 col-lg-3 mb-5 mb-lg-0">
						<div className="counter appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="200">
							<i className="icons icon-user text-8 mb-3"></i>
							<strong className="font-weight-extra-bold mb-1" data-to="10" data-append="+">
								0
							</strong>
							<label>Happy Clients</label>
							<p className="text-color-primary text-2 line-height-1 mb-0">They can’t be wrong</p>
						</div>
					</div>
					<div className="col-sm-6 col-lg-3 mb-5 mb-lg-0">
						<div className="counter appear-animation" data-appear-animation="fadeInLeftShorter">
							<i className="icons icon-screen-desktop text-8 mb-3"></i>
							<strong className="font-weight-extra-bold mb-1" data-to="15">
								0
							</strong>
							<label>Applications Developed</label>
							<p className="text-color-primary text-2 line-height-1 mb-0">Many more to come</p>
						</div>
					</div>
					<div className="col-sm-6 col-lg-3 mb-5 mb-sm-0">
						<div className="counter appear-animation" data-appear-animation="fadeInRightShorter">
							<i className="icons icon-support text-8 mb-3"></i>
							<strong className="font-weight-extra-bold mb-1" data-to="100" data-append="+">
								0
							</strong>
							<label>Answered Tickets</label>
							<p className="text-color-primary text-2 line-height-1 mb-0">Satisfaction guaranteed</p>
						</div>
					</div>
					<div className="col-sm-6 col-lg-3">
						<div className="counter appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="200">
							<i className="icons icon-clock text-8 mb-3"></i>
							<strong className="font-weight-extra-bold mb-1" data-to="5000" data-append="+">
								0
							</strong>
							<label>Development Hours</label>
							<p className="text-color-primary text-2 line-height-1 mb-0">Experience counts</p>
						</div>
					</div>
				</div>
			</div>

			<hr className="opacity-6" />

			<section className="section section-height-3 bg-light border-0 m-0">
				<div className="container">
					<div className="row">
						<div className="col">
							<div
								className="owl-carousel owl-theme mb-0"
								data-plugin-options="{'responsive': {'0': {'items': 1}, '476': {'items': 1}, '768': {'items': 5}, '992': {'items': 7}, '1200': {'items': 7}}, 'autoplay': true, 'autoplayTimeout': 3000, 'dots': false}"
							>
								<div>
									<img className="img-fluid opacity-2" src={client2} alt="" />
								</div>
								<div>
									<img className="img-fluid opacity-2" src={client2} alt="" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

export default Home;

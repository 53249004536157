import {Link} from "react-router-dom";

import office1 from "../../assets/images/demos/digital-agency/office/office3.jpg"
import laptop_image from "../../assets/images/demos/digital-agency/laptop.png"

function Services() {
	return (
		<div>
			<div className="slider-container rev_slider_wrapper" style={{height: 350}}>
				<div
					id="revolutionSlider"
					className="slider rev_slider"
					data-version="5.4.8"
					data-plugin-revolution-slider
					data-plugin-options="{'delay': 9000, 'gridwidth': 800, 'gridheight': 350, 'spinner': 'off'}"
				>
					<ul>
						<li data-transition="fade">
							<img
								src={office1}
								alt=""
								data-bgposition="center center"
								data-bgfit="cover"
								data-bgrepeat="no-repeat"
								className="rev-slidebg"
							/>

							<div className="tp-dottedoverlay tp-opacity-overlay"></div>
						</li>
					</ul>
				</div>
			</div>

			<div className="container">
				<div className="row justify-content-center">
					<div className="col-lg-8 mt-5 title-with-video-custom text-center">
						<h1 className="mt-5 pt-5 font-weight-semibold">Services</h1>
					</div>
				</div>
			</div>

			<section className="section section-default mt-0">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-12 text-center">
							<h2 className="mt-5 mb-5 pb-0">
								We create amazing solutions for your problems.
							</h2>
						</div>
					</div>
				</div>
			</section>

			<section className="m-5 pb-3">
				<div className="container pt-4 pb-3">
					<div className="row">
						<div className="col text-center">
							<h2 className="mb-0 mt-3 font-weight-bold text-6">We Can Help You With:</h2>
							<p className="text-4 mb-3">24/7 available for our clients. </p>
							<div className="divider divider-primary divider-small divider-small-center mb-3">
								<hr />
							</div>
						</div>
					</div>
					<div className="row justify-content-center mt-5">
						<div className="col-md-10 col-lg-4 text-center">
							<div
								className="feature-box feature-box-style-4 justify-content-center appear-animation"
								data-appear-animation="fadeInUp"
								data-appear-animation-delay="0"
							>
								<span className="featured-boxes featured-boxes-style-6 p-0 m-0">
									<span className="featured-box featured-box-primary featured-box-effect-6 p-0 m-0">
										<span className="box-content p-0 m-0">
											<i className="icon-featured icon-bulb icons"></i>
										</span>
									</span>
								</span>
								<div className="feature-box-info">
									<h4 className="mb-2 mt-3 text-4 text-uppercase font-weight-bold">Strategy</h4>
									<p className="mb-4">
										We build solutions as a process. Our strategic work process helps to feel the customer and their needs.
									</p>
								</div>
							</div>
						</div>
						<div className="col-md-10 col-lg-4 text-center">
							<div
								className="feature-box feature-box-style-4 justify-content-center appear-animation"
								data-appear-animation="fadeInUp"
								data-appear-animation-delay="300"
							>
								<span className="featured-boxes featured-boxes-style-6 p-0 m-0">
									<span className="featured-box featured-box-primary featured-box-effect-6 p-0 m-0">
										<span className="box-content p-0 m-0">
											<i className="icon-featured icon-puzzle icons"></i>
										</span>
									</span>
								</span>
								<div className="feature-box-info">
									<h4 className="mb-2 mt-3 text-4 text-uppercase font-weight-bold">Development</h4>
									<p className="mb-4">
										We have highly experienced engineers who have international work exposure.
									</p>
								</div>
							</div>
						</div>
						<div className="col-md-10 col-lg-4 text-center">
							<div
								className="feature-box feature-box-style-4 justify-content-center appear-animation"
								data-appear-animation="fadeInUp"
								data-appear-animation-delay="600"
							>
								<span className="featured-boxes featured-boxes-style-6 p-0 m-0">
									<span className="featured-box featured-box-primary featured-box-effect-6 p-0 m-0">
										<span className="box-content p-0 m-0">
											<i className="icon-featured icon-rocket icons"></i>
										</span>
									</span>
								</span>
								<div className="feature-box-info">
									<h4 className="mb-2 mt-3 text-4 text-uppercase font-weight-bold">Marketing</h4>
									<p className="mb-4">
										We have strong connections around and we think we have created a brand that everybody believes in.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<div className="container-fluid">
				<div className="row featured-boxes-full featured-boxes-full-scale clearfix">
					<div className="col-lg-4 featured-box-full featured-box-full-primary">
						<i className="icon-directions icons"></i>
						<h4 className="mt-3">1 - DESIGN</h4>
						<p>
							We emphasize on two way involvement while designing systems. We read client's mind and emotion to reflect the actual need in the architecture of the solution.
						</p>
					</div>
					<div className="col-lg-4 featured-box-full featured-box-full-primary">
						<i className="icon-puzzle icons"></i>
						<h4 className="mt-3">2 - CREATION</h4>
						<p>
							Creativity is an art. We follow artistic approach rather than hard rules for development of the solution.
						</p>
					</div>
					<div className="col-lg-4 featured-box-full featured-box-full-primary">
						<i className="icon-rocket icons"></i>
						<h4 className="mt-3">3 - LAUNCH</h4>
						<p>
							We provide fully tested and functional system within the committed deadline. We don't stop here, we provide after launch support too.
						</p>
					</div>
				</div>
			</div>

			<section className="section section-default section-footer section-color-custom mt-0 pt-2 pb-5">
				<div className="container py-4">
					<div className="row mt-2 mb-4 align-items-center">
						<div className="col-4 text-center">
							<img
								className="img-fluid appear-animation mt-5 pt-5"
								src={laptop_image}
								style={{width: '125%', maxWidth: '125%', left: '-25%', position: 'relative'}}
								alt="layout styles"
								data-appear-animation="fadeInLeft"
							/>
						</div>
						<div className="col-8">
							<h2 className="mb-1 mt-5 font-weight-bold text-6">Innovation</h2>
							<div className="divider divider-primary divider-small mb-5">
								<hr />
							</div>
							<p className="lead">
								We have always tried to be innovative in what we build.
							</p>
							<p>
								There is nothing new under the sun. Every human progress is the improvement of existing process.
								From digization of manual systems to improvement of existing digital systems, we have had innovative mindset.
							</p>

							<Link
								className="btn btn-outline btn-primary text-1 font-weight-semibold text-uppercase px-5 btn-py-2 mb-3"
								to="/contact"
							>
								Get a Quote
							</Link>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

export default Services;

import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Services from './components/Services';
import Work from './components/Work';
import React from 'react';

class App extends React.Component {

	// componentDidMount() {
	// 	window.location.reload()
	// }

	render() {
		return (
			<Router forceRefresh>
				<Header />
				<div role="main" className="main">
					<Switch>
						<Route exact path="/">
							<Home />
						</Route>
						<Route path="/about">
							<About />
						</Route>
						<Route path="/contact">
							<Contact />
						</Route>
						<Route path="/services">
							<Services />
						</Route>
						<Route render={() => <Redirect to="/" />} />
						{/* <Route path="/work">
						<Work />
					</Route> */}
					</Switch>
				</div>
				<Footer />
			</Router>
		);
	}
}

export default App;

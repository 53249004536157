import banner from "../../assets/images/demos/digital-agency/slides/image55.jpg";
import office1 from "../../assets/images/demos/digital-agency/office/office1.jpg";
import office2 from "../../assets/images/demos/digital-agency/office/office2.jpg";
import office3 from "../../assets/images/demos/digital-agency/office/office3.jpg";
import office4 from "../../assets/images/demos/digital-agency/office/office4.jpg";

function About() {
	return (
		<div>
			<section
				className="parallax section section-text-light section-parallax section-center mt-0 mb-5"
				data-plugin-parallax
				data-plugin-options="{'speed': 1.5}"
				data-image-src={banner}
				style={{minHeight: 560}}
			>
				<div className="container">
					<div className="row justify-content-center mt-5">
						<div className="col-lg-8 mt-5">
							<h1 className="mt-5 pt-5 font-weight-semibold">About</h1>
							<p className="mb-0 text-4 opacity-10">
								We are always there for our clients, helping them to excel.
							</p>
						</div>
					</div>
				</div>
			</section>

			<div className="container">
				<div className="row mb-5 pb-5 mt-5 pt-5">
					<div className="col-lg-9">
						<div className="row">
							<div className="col-lg-6">
								<h4 className="font-weight-extra-bold">Company</h4>
								<p className="">
									Clients are our assets and they have made us valuable.
								</p>
							</div>
							<div className="col-lg-6">
								<h4 className="font-weight-extra-bold">Mission</h4>
								<p className="">
									We have a dream to be a leader in the process of digization of world.
								</p>
							</div>
						</div>

						<blockquote className="mt-5 ml-4 blockquote-primary">
							<p>
								Customer Service is not a department in our company, its the whole company.
							</p>
							<footer>- Nirajan Timalsina, CEO</footer>
						</blockquote>
					</div>
					<div className="col-lg-3 d-none d-sm-block">
						<div className="row text-center mt-5 mt-lg-0">
							<div className="col-md-8 col-lg-6 mx-auto">
								<img className="img-fluid m-3 my-0 mt-lg-5" src={office1} alt="Office" />
								<img className="img-fluid m-3 my-0" src={office3} alt="Office" />
							</div>
							<div className="col-md-8 col-lg-6 mx-auto">
								<img className="img-fluid m-3 my-0" src={office2} alt="Office" />
								<img className="img-fluid m-3 my-0" src={office4} alt="Office" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<section className="section section-default border-0">
				<div className="container py-4">
					<div className="row counters">
						<div className="col-sm-6 col-lg-3 mb-5 mb-lg-0">
							<div className="counter">
								<i className="icons icon-user text-color-dark"></i>
								<strong className="text-color-dark font-weight-extra-bold" data-to="10" data-append="+">
									0
								</strong>
								<label className="text-4 mt-1">Happy Clients</label>
							</div>
						</div>
						<div className="col-sm-6 col-lg-3 mb-5 mb-lg-0">
							<div className="counter">
								<i className="icons icon-badge text-color-dark"></i>
								<strong className="text-color-dark font-weight-extra-bold" data-to="3">
									0
								</strong>
								<label className="text-4 mt-1">Years In Business</label>
							</div>
						</div>
						<div className="col-sm-6 col-lg-3 mb-5 mb-sm-0">
							<div className="counter">
								<i className="icons icon-graph text-color-dark"></i>
								<strong className="text-color-dark font-weight-extra-bold" data-to="60" data-append="%">
									0
								</strong>
								<label className="text-4 mt-1">Yearly Growth</label>
							</div>
						</div>
						<div className="col-sm-6 col-lg-3">
							<div className="counter">
								<i className="icons icon-cup text-color-dark"></i>
								<strong className="text-color-dark font-weight-extra-bold" data-to="500" data-append="+">
									0
								</strong>
								<label className="text-4 mt-1">Cups of Coffee</label>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="m-5 pb-3">
				<div className="container py-4">
					<div className="row">
						<div className="col-lg-12 text-center">
							<h2 className="mb-0 mt-3 font-weight-extra-bold text-6">Leadership</h2>
							<p className="text-4 mb-3">We believe leadership is an action, not a position. </p>
							<div className="divider divider-primary divider-small divider-small-center mb-3">
								<hr />
							</div>
						</div>
					</div>
					<div className="row justify-content-center mt-4">
						<div className="col-lg-8">
							<div className="row mt-4">
								<div className="col-lg-6 col-12 text-center mb-4">
									<h4 className="mt-3 mb-0">Nirajan Timalsina</h4>
									<p className="mb-0">CEO</p>
									<span className="thumb-info-social-icons mt-2 pb-0">
										<a href="https://www.facebook.com/nirajan.timalsina.31/" target="_blank">
											<i className="fab fa-facebook-f"></i>
											<span>Facebook</span>
										</a>
										{/* <a href="http://www.twitter.com">
											<i className="fab fa-twitter"></i>
											<span>Twitter</span>
										</a> */}
										<a href="mailto:support@perfexdata.com">
											<i className="far fa-envelope"></i>
											<span>Email</span>
										</a>
										<a href="https://www.linkedin.com/in/nirajantimalsina/"  target="_blank">
											<i className="fab fa-linkedin-in"></i>
											<span>Linkedin</span>
										</a>
									</span>
								</div>
								<div className="col-lg-6 col-12 text-center mb-4">
									<h4 className="mt-3 mb-0">Nabin Gelal</h4>
									<p className="mb-0">COO</p>
									<span className="thumb-info-social-icons mt-2 pb-0">
										<a href="https://www.facebook.com/carles.nabin" target="_blank">
											<i className="fab fa-facebook-f"></i>
											<span>Facebook</span>
										</a>
										<a href="mailto:support@perfexdata.com">
											<i className="far fa-envelope"></i>
											<span>Email</span>
										</a>
									</span>
								</div>
								{/* <div className="col-lg-3 col-12 text-center mb-4">
									<h4 className="mt-3 mb-0">Craig Doe</h4>
									<p className="mb-0">COO</p>
									<span className="thumb-info-social-icons mt-2 pb-0">
										<a href="http://www.facebook.com" target="_blank">
											<i className="fab fa-facebook-f"></i>
											<span>Facebook</span>
										</a>
										<a href="http://www.twitter.com">
											<i className="fab fa-twitter"></i>
											<span>Twitter</span>
										</a>
										<a href="http://www.linkedin.com">
											<i className="fab fa-linkedin-in"></i>
											<span>Linkedin</span>
										</a>
									</span>
								</div>
								<div className="col-lg-3 col-12 text-center mb-4">
									<h4 className="mt-3 mb-0">Richard Doe</h4>
									<p className="mb-0">President</p>
									<span className="thumb-info-social-icons mt-2 pb-0">
										<a href="http://www.linkedin.com">
											<i className="fab fa-linkedin-in"></i>
											<span>Linkedin</span>
										</a>
									</span>
								</div> */}
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* <section className="section section-default section-footer border-0">
				<div className="container py-4">
					<div className="row">
						<div className="col-lg-12 text-center">
							<h2 className="mb-0 mt-3 font-weight-extra-bold text-6">Clients</h2>
							<p className="text-4 mb-3">Pellentesque pellentesque eget tempor tellus. </p>
							<div className="divider divider-primary divider-small divider-small-center mb-3">
								<hr />
							</div>
						</div>
					</div>
					<div className="row mt-4">
						<div className="content-grid col mt-5 mb-4">
							<div className="row content-grid-row">
								<div className="content-grid-item col-lg-4 text-center py-4">
									<img className="img-fluid" src="img/logos/logo-1.png" style={{maxWidth: 180}} alt="" />
								</div>
								<div className="content-grid-item col-lg-4 text-center py-4">
									<img className="img-fluid" src="img/logos/logo-2.png" style={{maxWidth: 180}} alt="" />
								</div>
								<div className="content-grid-item col-lg-4 text-center py-4">
									<img className="img-fluid" src="img/logos/logo-3.png" style={{maxWidth: 180}} alt="" />
								</div>
							</div>
							<div className="row content-grid-row">
								<div className="content-grid-item col-lg-4 text-center py-4">
									<img className="img-fluid" src="img/logos/logo-4.png" style={{maxWidth: 180}} alt="" />
								</div>
								<div className="content-grid-item col-lg-4 text-center py-4">
									<img className="img-fluid" src="img/logos/logo-5.png" style={{maxWidth: 180}} alt="" />
								</div>
								<div className="content-grid-item col-lg-4 text-center py-4">
									<img className="img-fluid" src="img/logos/logo-6.png" style={{maxWidth: 180}} alt="" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section> */}
		</div>
	);
}

export default About;

function Contact() {
	return (
		<div>
			<section className="section section-text-light section-default bg-color-dark section-center border-top-0 mt-0">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-8 mt-5">
							<h1 className="mt-5 font-weight-semibold">Say Hello</h1>
							<p className="mb-0 text-4 opacity-7">
								24/7 available to build fantastic solutions and answer queries
							</p>
						</div>
					</div>
					<div className="row justify-content-center mt-4">
						<div className="col-lg-10">
							<div className="row justify-content-between mt-4 text-left">
								<div className="col-lg-5 mb-4">
									<h4 className="mt-3 mb-0">KATHMANDU</h4>
									<ul className="list list-icons mt-3">
										<li>
											<i className="fas fa-map-marker-alt"></i> <strong>Address:</strong> Koteshor
										</li>
										<li>
											<i className="fas fa-phone"></i> <strong>Phone:</strong> 9851191843
										</li>
										<li>
											<i className="far fa-envelope"></i> <strong>Email:</strong>{' '}
											<a href="mailto:mail@example.com">support@perfexdata.com</a>
										</li>
									</ul>
								</div>
								<div className="col-lg-5 mb-4">
									<h4 className="mt-3 mb-0">BHAKTAPUR</h4>
									<ul className="list list-icons mt-3">
										<li>
											<i className="fas fa-map-marker-alt"></i> <strong>Address:</strong> RadheRadhe
										</li>
										<li>
											<i className="fas fa-phone"></i> <strong>Phone:</strong> 9841691840
										</li>
										<li>
											<i className="far fa-envelope"></i> <strong>Email:</strong>{' '}
											<a href="mailto:mail@example.com">support@perfexdata.com</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div className="row justify-content-center mt-4">
						<div className="col-lg-10 text-center">
							<div className="divider divider-primary divider-small divider-small-center mb-3">
								<hr />
							</div>

							<h2 className="mb-0 mt-4 font-weight-semibold">Send us a Message.</h2>
							{/* <p className="text-4 mb-0 opacity-7">Pellentesque pellentesque eget tempor tellus. </p> */}

							<div className="divider divider-style-4 divider-primary divider-top-section-custom taller">
								<i className="fas fa-chevron-up"></i>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* <div className="container">
				<div className="row justify-content-center mt-5">
					<div className="col-lg-10 py-4">
						<form id="contactForm" className="contact-form" action="php/contact-form.php" method="POST">
							<div className="contact-form-success alert alert-success d-none mt-4" id="contactSuccess">
								<strong>Success!</strong> Your message has been sent to us.
							</div>

							<div className="contact-form-error alert alert-danger d-none mt-4" id="contactError">
								<strong>Error!</strong> There was an error sending your message.
								<span className="mail-error-message text-1 d-block" id="mailErrorMessage"></span>
							</div>
							<div className="form-row">
								<div className="form-group col">
									<input
										type="text"
										placeholder="Your Name"
										value=""
										data-msg-required="Please enter your name."
										maxLength="100"
										className="form-control form-control-lg py-3 text-3"
										name="name"
										id="name"
										required
									/>
								</div>
							</div>
							<div className="form-row">
								<div className="form-group col">
									<input
										type="email"
										placeholder="Your E-mail"
										value=""
										data-msg-required="Please enter your email address."
										data-msg-email="Please enter a valid email address."
										maxLength="100"
										className="form-control form-control-lg py-3 text-3"
										name="email"
										id="email"
										required
									/>
								</div>
							</div>
							<div className="form-row">
								<div className="form-group col">
									<input
										type="text"
										placeholder="Subject"
										value=""
										data-msg-required="Please enter the subject."
										maxLength="100"
										className="form-control form-control-lg py-3 text-3"
										name="subject"
										id="subject"
										required
									/>
								</div>
							</div>
							<div className="form-row">
								<div className="form-group col">
									<textarea
										maxLength="5000"
										placeholder="Message"
										data-msg-required="Please enter your message."
										rows="10"
										className="form-control form-control-lg py-3 text-3"
										name="message"
										id="message"
										required
									></textarea>
								</div>
							</div>
							<div className="form-row">
								<div className="form-group col">
									<input
										type="submit"
										value="Send Message"
										className="btn btn-outline btn-dark text-2 font-weight-semibold text-uppercase px-5 btn-py-3"
										data-loading-text="Loading..."
									/>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div> */}

			{/* <div id="googlemaps" className="google-map"></div> */}
		</div>
	);
}

export default Contact;

import logo from "../../assets/images/perfexdatalogo.png";

function Footer() {
	return (
		<footer id="footer">
			<div className="container">
				<div className="row py-5">
					<div className="col-md-4 d-flex justify-content-center justify-content-md-start mb-4 mb-lg-0">
						<a href="index.html" className="logo pr-0 pr-lg-3 pl-3 pl-md-0">
							<img alt="Porto Website Template" src={logo} height="33" />
						</a>
					</div>
					<div className="col-md-8 d-flex justify-content-center justify-content-md-end mb-4 mb-lg-0">
						<div className="row">
							<div className="col-md-12 mb-6 mb-md-0">
								<div className="ml-3 text-center text-md-right">
									<h5 className="text-3 mb-0 text-color-light">KATHMANDU</h5>
									<p className="text-4 mb-0">
										<i className="fab fa-whatsapp text-color-primary top-1 p-relative"></i>
										<span className="pl-1">(+977) 9851191843</span>
									</p>
								</div>
							</div>
							{/* <div className="col-md-6">
								<div className="ml-3 text-center text-md-right">
									<h5 className="text-3 mb-0 text-color-light">Office</h5>
									<p className="text-4 mb-0">
										<i className="fab fa-whatsapp text-color-primary top-1 p-relative"></i>
										<span className="pl-1">(+977) 01-5221070</span>
									</p>
								</div>
							</div> */}
						</div>
					</div>
				</div>
			</div>
			<div className="footer-copyright footer-copyright-style-2">
				<div className="container py-2">
					<div className="row py-4">
						<div className="col-md-4 d-flex align-items-center justify-content-center justify-content-md-start mb-2 mb-lg-0">
							<p>© Copyright 2021. All Rights Reserved.</p>
						</div>
						{/* <div className="col-md-8 d-flex align-items-center justify-content-center justify-content-md-end mb-4 mb-lg-0">
							<p>
								<i className="far fa-envelope text-color-primary top-1 p-relative"></i>
								<a href="mailto:mail@example.com" className="opacity-7 pl-1">
									mail@example.com
								</a>
							</p>
							<ul className="footer-social-icons social-icons social-icons-clean social-icons-icon-light ml-3">
								<li className="social-icons-facebook">
									<a href="http://www.facebook.com/" target="_blank" title="Facebook">
										<i className="fab fa-facebook-f"></i>
									</a>
								</li>
								<li className="social-icons-twitter">
									<a href="http://www.twitter.com/" target="_blank" title="Twitter">
										<i className="fab fa-twitter"></i>
									</a>
								</li>
								<li className="social-icons-linkedin">
									<a href="http://www.linkedin.com/" target="_blank" title="Linkedin">
										<i className="fab fa-linkedin-in"></i>
									</a>
								</li>
							</ul>
						</div> */}
					</div>
				</div>
			</div>
		</footer>
	);
}

export default Footer;

import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import logo from '../../assets/images/perfexdatalogo.png';

class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			navExpanded: false,
		};
	}

	navClicked() {
		this.setState({ navExpanded: !this.state.navExpanded });
	}

	render() {
		return (
			<header
				id="header"
				className="header-transparent header-semi-transparent header-semi-transparent-dark header-effect-shrink"
				data-plugin-options={{
					stickyEnabled: true,
					stickyEffect: 'shrink',
					stickyEnableOnBoxed: true,
					stickyEnableOnMobile: true,
					stickyChangeLogo: true,
					stickyStartAt: 30,
					stickyHeaderContainerHeight: 70,
				}}
			>
				<div
					className="header-body border-top-0 bg-dark box-shadow-none"
					style={{ position: 'fixed', height: this.state.navExpanded ? '314px' : 'auto' }}
				>
					<div className="header-container container">
						<div className="header-row">
							<div className="header-column">
								<div className="header-row">
									<div className="header-logo">
										<Link to="/">
											<img alt="Porto" width="190px" height="50px" src={logo} style={{ display: 'block' }} />
										</Link>
									</div>
								</div>
							</div>
							<div className="header-column justify-content-end">
								<div className="header-row">
									<div className="header-nav header-nav-links header-nav-dropdowns-dark header-nav-light-text order-2 order-lg-1">
										<div className="header-nav-main header-nav-main-mobile-dark header-nav-main-square header-nav-main-dropdown-no-borders header-nav-main-effect-2 header-nav-main-sub-effect-1">
											<nav className="collapse">
												<ul className="nav nav-pills" id="mainNav">
													<li>
														<NavLink exact={true} className="nav-link" activeClassName="active" to="/">
															Home
														</NavLink>
													</li>
													<li>
														<NavLink className="nav-link" activeClassName="active" to="/about">
															About
														</NavLink>
													</li>
													<li>
														<NavLink className="nav-link" activeClassName="active" to="/services">
															Services
														</NavLink>
													</li>
													{/* <li>
													<Link className="nav-link" to="/work">
														Work
													</Link>
												</li> */}
													<li>
														<NavLink className="nav-link" activeClassName="active" to="/contact">
															Contact
														</NavLink>
													</li>
												</ul>
											</nav>
										</div>
										<button
											onClick={() => this.navClicked()}
											className={`btn header-btn-collapse-nav${this.state.navExpanded?'':' collapsed'}`}
											data-toggle="collapse"
											data-target=".header-nav-main nav"
											aria-expanded={this.state.navExpanded}
										>
											<i className="fas fa-bars"></i>
										</button>
									</div>
									{/* <div className="ml-2 order-1 order-lg-2">
									<ul className="header-social-icons social-icons d-none d-sm-block social-icons-clean ml-0">
										<li className="social-icons-facebook">
											<a href="http://www.facebook.com/" target="_blank" title="Facebook">
												<i className="fab fa-facebook-f"></i>
											</a>
										</li>
										<li className="social-icons-twitter">
											<a href="http://www.twitter.com/" target="_blank" title="Twitter">
												<i className="fab fa-twitter"></i>
											</a>
										</li>
										<li className="social-icons-linkedin">
											<a href="http://www.linkedin.com/" target="_blank" title="Linkedin">
												<i className="fab fa-linkedin-in"></i>
											</a>
										</li>
									</ul>
								</div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</header>
		);
	}
}

export default Header;

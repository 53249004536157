import project_image from '../../assets/images/projects/project.jpg';
import project1_image from '../../assets/images/projects/project-1.jpg';
import project3_image from '../../assets/images/projects/project-3.jpg';
import project5_image from '../../assets/images/projects/project-5.jpg';
import project6_image from '../../assets/images/projects/project-6.jpg';
import project25_image from '../../assets/images/projects/project-25.jpg';

function Work() {
	return (
		<div>
			<section
				className="parallax section section-text-light section-parallax section-center mt-0 mb-5"
				data-plugin-parallax
				data-plugin-options="{'speed': 1.5}"
				data-image-src="img/demos/digital-agency/slides/slide-digital-agency-4.jpg"
				style={{ minHeight: 560 }}
			>
				<div className="container">
					<div className="row justify-content-center mt-5">
						<div className="col-lg-8 mt-5">
							<h1 className="mt-5 pt-5 font-weight-semibold">Work</h1>
							<p className="mb-0 text-4 opacity-7">
								Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Nulla
								consequat massa quis enim.
							</p>
						</div>
					</div>
				</div>
			</section>

			<div className="container">
				<ul
					className="nav nav-pills sort-source mt-5 mb-5"
					data-sort-id="portfolio"
					data-option-key="filter"
					data-plugin-options="{'layoutMode': 'fitRows', 'filter': '*'}"
				>
					<li className="nav-item" data-option-value="*">
						<a className="nav-link text-1 text-uppercase active" href="#">
							Show All
						</a>
					</li>
					<li className="nav-item" data-option-value=".websites">
						<a className="nav-link text-1 text-uppercase" href="#">
							Websites
						</a>
					</li>
					<li className="nav-item" data-option-value=".logos">
						<a className="nav-link text-1 text-uppercase" href="#">
							Logos
						</a>
					</li>
					<li className="nav-item" data-option-value=".brands">
						<a className="nav-link text-1 text-uppercase" href="#">
							Brands
						</a>
					</li>
					<li className="nav-item" data-option-value=".medias">
						<a className="nav-link text-1 text-uppercase" href="#">
							Medias
						</a>
					</li>
				</ul>

				<div className="sort-destination-loader sort-destination-loader-showing">
					<div className="row portfolio-list no-gutters sort-destination mb-5" data-sort-id="portfolio">
						<div className="col-md-6 col-lg-4 isotope-item m-0 p-0 brands">
							<div className="portfolio-item m-0">
								<a href="#">
									<span className="thumb-info thumb-info-centered-info thumb-info-no-borders m-0">
										<span className="thumb-info-wrapper">
											<img src={project_image} className="img-fluid" alt="" />
											<span className="thumb-info-title">
												<span className="thumb-info-inner">Presentation</span>
												<span className="thumb-info-type">Brand</span>
											</span>
											<span className="thumb-info-action">
												<span className="thumb-info-action-icon">
													<i className="fas fa-plus"></i>
												</span>
											</span>
										</span>
									</span>
								</a>
							</div>
						</div>
						<div className="col-md-6 col-lg-4 isotope-item m-0 p-0 medias">
							<div className="portfolio-item m-0">
								<a href="#">
									<span className="thumb-info thumb-info-centered-info thumb-info-no-borders m-0">
										<span className="thumb-info-wrapper">
											<img src={project1_image} className="img-fluid" alt="" />
											<span className="thumb-info-title">
												<span className="thumb-info-inner">Porto Mockup</span>
												<span className="thumb-info-type">Media</span>
											</span>
											<span className="thumb-info-action">
												<span className="thumb-info-action-icon">
													<i className="fas fa-plus"></i>
												</span>
											</span>
										</span>
									</span>
								</a>
							</div>
						</div>
						<div className="col-md-6 col-lg-4 isotope-item m-0 p-0 brands">
							<div className="portfolio-item m-0">
								<a href="#">
									<span className="thumb-info thumb-info-centered-info thumb-info-no-borders m-0">
										<span className="thumb-info-wrapper">
											<img src={project3_image} className="img-fluid" alt="" />
											<span className="thumb-info-title">
												<span className="thumb-info-inner">Watch Mockup</span>
												<span className="thumb-info-type">Brand</span>
											</span>
											<span className="thumb-info-action">
												<span className="thumb-info-action-icon">
													<i className="fas fa-plus"></i>
												</span>
											</span>
										</span>
									</span>
								</a>
							</div>
						</div>
						<div className="col-md-6 col-lg-4 isotope-item m-0 p-0 logos">
							<div className="portfolio-item m-0">
								<a href="#">
									<span className="thumb-info thumb-info-centered-info thumb-info-no-borders m-0">
										<span className="thumb-info-wrapper">
											<img src={project25_image} className="img-fluid" alt="" />
											<span className="thumb-info-title">
												<span className="thumb-info-inner">Black Watch</span>
												<span className="thumb-info-type">Media - Medium</span>
											</span>
											<span className="thumb-info-action">
												<span className="thumb-info-action-icon">
													<i className="fas fa-plus"></i>
												</span>
											</span>
										</span>
									</span>
								</a>
							</div>
						</div>
						<div className="col-md-6 col-lg-4 isotope-item m-0 p-0 brands">
							<div className="portfolio-item m-0">
								<a href="#">
									<span className="thumb-info thumb-info-centered-info thumb-info-no-borders m-0">
										<span className="thumb-info-wrapper">
											<img src={project5_image} className="img-fluid" alt="" />
											<span className="thumb-info-title">
												<span className="thumb-info-inner">Company T-Shirt</span>
												<span className="thumb-info-type">Brand</span>
											</span>
											<span className="thumb-info-action">
												<span className="thumb-info-action-icon">
													<i className="fas fa-plus"></i>
												</span>
											</span>
										</span>
									</span>
								</a>
							</div>
						</div>
						<div className="col-md-6 col-lg-4 isotope-item m-0 p-0 websites">
							<div className="portfolio-item m-0">
								<a href="#">
									<span className="thumb-info thumb-info-centered-info thumb-info-no-borders m-0">
										<span className="thumb-info-wrapper">
											<img src={project6_image} className="img-fluid" alt="" />
											<span className="thumb-info-title">
												<span className="thumb-info-inner">Mobile Mockup</span>
												<span className="thumb-info-type">Website</span>
											</span>
											<span className="thumb-info-action">
												<span className="thumb-info-action-icon">
													<i className="fas fa-plus"></i>
												</span>
											</span>
										</span>
									</span>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Work;
